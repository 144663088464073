import endpoints from 'utilities/endpoints-helper/endpoints-helper';

export default (hash) => {
  return fetch(endpoints('confirmRequest'), {
    method: 'POST',
    body: JSON.stringify({ hash, origin: window.location.origin }),
  }).then((response) => {
    return response.json();
  });
};
