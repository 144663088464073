import React from 'react';

const ErrorImg = () => (
  <div className="ErrorImg">
    <svg
      aria-hidden="true"
      xmlns="http://www.w3.org/2000/svg"
      className="ErrorImg__image"
      width="436"
      viewBox="0 0 436 281"
      data-testid="error-image"
    >
      <g fill="none" fillRule="evenodd" transform="translate(0 1)">
        <ellipse cx="69.399" cy="270.98" fill="#F2F2F2" fillRule="nonzero" rx="55.284" ry="9.02" />
        <ellipse cx="73.32" cy="265.49" stroke="#3F3D56" strokeWidth=".84" rx="55.284" ry="9.02" />
        <rect
          width="166.245"
          height="98.824"
          x="33.719"
          y="11.765"
          fill="#FF8A28"
          fillRule="nonzero"
          rx="19.193"
        />
        <rect
          width="166.245"
          height="98.824"
          x="251.719"
          y="90.196"
          fill="#F2F2F2"
          fillRule="nonzero"
          rx="19.193"
        />
        <rect
          width="166.245"
          height="98.824"
          x="21.173"
          stroke="#3F3D56"
          strokeWidth=".84"
          rx="19.193"
        />
        <rect
          width="166.245"
          height="98.824"
          x="239.173"
          y="79.216"
          stroke="#3F3D56"
          strokeWidth=".84"
          rx="19.193"
        />
        <path
          stroke="#3F3D56"
          strokeWidth=".84"
          d="M396.243 29.02a9.46 9.46 0 0 0-8.153 5.732c-2.04 4.955.586 10.775 4.527 14.388 3.94 3.614 9.002 5.679 13.602 8.394 6.177 3.646 11.728 8.708 14.82 15.205 3.09 6.498 3.419 14.546-.214 20.754-3.373 5.761-9.505 9.21-15.353 12.39"
        />
        <ellipse cx="401.496" cy="30.196" fill="#FF8A28" fillRule="nonzero" rx="7.058" ry="3.529" />
        <ellipse cx="406.986" cy="52.941" fill="#FF8A28" fillRule="nonzero" rx="7.058" ry="3.529" />
        <ellipse cx="393.655" cy="56.863" fill="#FF8A28" fillRule="nonzero" rx="7.058" ry="3.529" />
        <ellipse cx="414.043" cy="76.471" fill="#FF8A28" fillRule="nonzero" rx="7.058" ry="3.529" />
        <ellipse cx="428.942" cy="76.471" fill="#FF8A28" fillRule="nonzero" rx="7.058" ry="3.529" />
        <path
          stroke="#3F3D56"
          strokeWidth=".84"
          d="M9.646 187.451a9.46 9.46 0 0 0-8.154 5.732c-2.038 4.955.587 10.775 4.527 14.389 3.941 3.613 9.003 5.678 13.602 8.393 6.178 3.646 11.73 8.708 14.82 15.206 3.091 6.497 3.42 14.546-.213 20.753-3.373 5.761-9.505 9.21-15.353 12.39"
        />
        <ellipse cx="14.899" cy="188.627" fill="#FF8A28" fillRule="nonzero" rx="7.058" ry="3.529" />
        <ellipse cx="20.388" cy="211.373" fill="#FF8A28" fillRule="nonzero" rx="7.058" ry="3.529" />
        <ellipse cx="7.058" cy="215.294" fill="#FF8A28" fillRule="nonzero" rx="7.058" ry="3.529" />
        <ellipse cx="28.23" cy="234.118" fill="#FF8A28" fillRule="nonzero" rx="7.058" ry="3.529" />
        <ellipse cx="42.345" cy="234.118" fill="#FF8A28" fillRule="nonzero" rx="7.058" ry="3.529" />
        <path
          fill="#FFC1C7"
          fillRule="nonzero"
          d="M105.448 112.24s.83 6.191-1.245 8.668c-2.076 2.477-5.397 18.989-5.397 18.989v9.907s12.87-18.576 15.36-26.006c2.491-7.43 0-13.21 0-13.21l-8.718 1.651z"
        />
        <path
          fill="#3F3D56"
          fillRule="nonzero"
          d="M104.22 81.569l.172.198a26.537 26.537 0 0 1 4.868 8.241c2.674 7.261 8.42 25.16 6.361 26.964-2.85 2.499-10.994 4.165-11.401 3.749-.407-.417-8.55-26.24-8.55-26.24l8.55-12.912zM91.045 257.003s9.99 2.038 10.406 0a16.08 16.08 0 0 1 1.249-3.67s9.054.37 6.69 8.145a3.26 3.26 0 0 1-1.453 1.835c-2.156 1.292-7.235 4.34-9.816 5.92-1.939 1.187-4.726.3-6.715-.648-1.466-.703-2.262-2.287-1.939-3.854l1.578-7.728zM62.57 257.787s-9.946 2.038-10.36 0a16.128 16.128 0 0 0-1.243-3.67s-8.998.37-6.668 8.123c.24.78.764 1.447 1.473 1.87 2.158 1.298 7.192 4.333 9.753 5.907 2.115 1.3 5.24.113 7.228-.918a2.822 2.822 0 0 0 1.494-3.064l-1.677-8.248z"
        />
        <path
          fill="#FFC1C7"
          fillRule="nonzero"
          d="M49.818 116.943s-.83 6.184 1.245 8.658c2.076 2.473 5.397 11.956 5.397 11.956v9.894s-12.87-11.544-15.36-18.964c-2.491-7.421 0-13.193 0-13.193l8.718 1.65z"
        />
        <ellipse
          cx="79.201"
          cy="56.471"
          fill="#FFC1C7"
          fillRule="nonzero"
          rx="10.194"
          ry="10.196"
        />
        <path
          fill="#FFC1C7"
          fillRule="nonzero"
          d="M86.767 60.476s-2.83 13.216 0 14.868c2.829 1.652-16.976 0-16.976 0s4.446-11.151 2.425-14.868c-2.02-3.718 14.55 0 14.55 0z"
        />
        <path
          fill="#2F2E41"
          fillRule="nonzero"
          d="M96.805 148.602s3.686 5.363 2.457 21.863c-1.228 16.5-2.457 33.826-2.457 33.826s9.01 51.977 6.143 53.214c-2.867 1.238-12.285 2.475-13.514 1.238-1.228-1.238-6.961-49.502-6.961-49.502l-5.324-39.6-8.19 45.376s-4.505 44.138-7.78 46.201c-2.342 1.474-6.986.42-9.444-.302a2.152 2.152 0 0 1-1.546-2.162c.3-6.941 1.648-34.335 4.847-51.163 3.685-19.388-3.686-58.164 2.866-58.989 6.552-.825 38.903 0 38.903 0z"
        />
        <path
          fill="#3F3D56"
          fillRule="nonzero"
          d="M68.176 73.325s1.657-7.419 7.87-6.594c6.214.824 10.357.412 11.6 4.533 1.242 4.122 14.498 7.42 14.498 7.42s3.314.411 2.9 5.77c-.415 5.358-8.7 25.142-8.7 25.142s.415 16.486 2.486 23.905c2.07 7.419 2.07 16.486-2.486 18.547s-33.138 9.48-39.766.412c0 0-3.728-13.189-1.243-18.547 2.485-5.358-.414-26.378-.414-26.378l-7.87-17.311s2.485-8.243 9.527-10.716a42.237 42.237 0 0 0 11.598-6.183z"
        />
        <path
          fill="#3F3D56"
          fillRule="nonzero"
          d="M50.89 87.059l-3.318 3.735s-9.951 24.486-7.049 26.976c2.903 2.49 11.196 4.15 11.61 3.735.415-.415 7.464-21.58 7.464-21.58L50.89 87.058z"
        />
        <path
          fill="#2F2E41"
          fillRule="nonzero"
          d="M90.964 50.155s-1.628 1.23-2.035 4.099c-.407 2.869-2.906-1.635-3.488 4.306-.582 5.94-10.35 10.039-14.014 7.17-3.663-2.87-9.768-20.903-1.22-23.772 8.547-2.87 17.094-2.46 18.722.41 1.628 2.869 2.035 7.787 2.035 7.787z"
        />
        <path
          fill="#000"
          fillRule="nonzero"
          d="M82.751 58.561c.6-5.941 3.177-1.437 3.597-4.306.42-2.87 2.098-4.1 2.098-4.1s-.42-4.918-2.098-7.787c-.803-1.373-3.242-2.181-6.53-2.338 4.536-.203 8.05.633 9.048 2.338 1.678 2.869 2.098 7.788 2.098 7.788s-1.679 1.23-2.098 4.099c-.42 2.869-2.997-1.635-3.597 4.306-.509 5.037-7.824 8.747-12.34 8.013 4.304-.578 9.4-3.839 9.822-8.013z"
          opacity=".1"
        />
      </g>
    </svg>
  </div>
);

export default ErrorImg;
