import React, { lazy, Suspense } from 'react';

import {
  getCurrentDomain,
  getFormattedDomainName,
  getAppUrlOrigin,
  getViewParameter,
} from 'utilities/domain-detection/domain-detection';
import { domainNames } from 'utilities/domain-data/domain-data';
import FooterCopy from 'components/FooterCopy/FooterCopy';
import { CONTACT_US } from 'static/links/sharedLinks';

const FooterDisclaimer = () => {
  const domain = getCurrentDomain();

  const appUrlOrigin = getAppUrlOrigin();

  const prettyDomain = getFormattedDomainName();

  const view = getViewParameter();

  if (domain === domainNames.GFA) {
    const GFAFooterDisclaimer = lazy(() =>
      import('components/FooterDisclaimer/GFAFooterDisclaimer')
    );

    return (
      <Suspense fallback={<div className="FooterDisclaimer">Loading...</div>}>
        <GFAFooterDisclaimer />
      </Suspense>
    );
  }

  return (
    <div className="FooterDisclaimer">
      <FooterCopy
        contactUsLink={
          view === 'tu'
            ? {
                ...CONTACT_US,
                href: `${appUrlOrigin}/${view}${CONTACT_US.href}`,
              }
            : undefined
        }
        copyrightYear={new Date().getFullYear()}
        prettyDomain={prettyDomain}
        view={view}
      />
    </div>
  );
};

export default FooterDisclaimer;
