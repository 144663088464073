import React from 'react';

import withPageLayout from 'hocs/withPageLayout';

import ConfirmationImg from 'components/ConfirmationImg/ConfirmationImg';

const VerifyIdentificationPage = () => {
  return (
    <div className="container-fluid Page">
      <div className="row align-items-center justify-content-center Page__image">
        <ConfirmationImg />
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-12 text-center">
          <h2 className="Page__header">Thank You For Your Submission</h2>

          <div className="Page__content">
            We&apos;ve sent a verification link to the email address you submitted. In order to
            proceed with your request, we need to verify your identity. You have 24 hours to respond
            before the link expires. If you have not received it, please check your spam or junk
            folder.
          </div>

          <a
            href="https://www.gobankingrates.com/category/credit/credit-score/"
            data-testid="credit-score-resources"
          >
            <button className="Page__cta" type="button">
              Credit Score Resources
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default withPageLayout(VerifyIdentificationPage, {
  pageTitle: 'verifyIdentification',
});
