import React from 'react';
import { createRoot } from 'react-dom/client';

import { AppStateProvider } from 'contexts/app-state';

import Root from 'components/Root/Root';

import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <AppStateProvider>
    <Root />
  </AppStateProvider>
);
