import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import queryString from 'query-string';

import withPageLayout from 'hocs/withPageLayout';

import ManageMyDataForm from 'components/ManageMyDataForm/ManageMyDataForm';

import {
  getAppUrlOrigin,
  getCurrentDomain,
  getViewParameter,
} from 'utilities/domain-detection/domain-detection';
import { domainNames } from 'utilities/domain-data/domain-data';
import routes from 'utilities/routes-helper/routes-helper';

const PrivacyAndTermsProps = {
  domain: PropTypes.string,
};

// INFO: Point back to GFA's privacy policy & terms of use
const PrivacyAndTerms = React.memo(({ domain }) => {
  const appUrlOrigin = getAppUrlOrigin();

  const view = getViewParameter();

  switch (domain) {
    case domainNames.GFA:
      return (
        <>
          <a href={`${appUrlOrigin}/privacy`} target="_blank" rel="noreferrer">
            Privacy Policy
          </a>{' '}
          &amp;{' '}
          <a href={`${appUrlOrigin}/terms`} target="_blank" rel="noreferrer">
            Terms of Use
          </a>
        </>
      );

    default:
      return (
        <Link to={`${routes('privacyPolicy')}?view=${view}`}>
          Privacy Policy &amp; Terms of Use
        </Link>
      );
  }
});

PrivacyAndTerms.propTypes = PrivacyAndTermsProps;

const ManageMyDataPage = () => {
  const location = useLocation();

  const query = location.search ? queryString.parse(location.search) : '';
  const domain = getCurrentDomain();

  return (
    <div>
      <div className="container-fluid Page ManageMyDataPage">
        <div className="row">
          <div className="col-12">
            <h1>Manage My Data</h1>

            <p>
              Your privacy is important to us. Under our <PrivacyAndTerms domain={domain} />, you
              have the right to access, know about, and delete all of your personal information we
              collect within the past 12 months up to 2 times within 12-month period. We will
              respond to your request promptly within a reasonable timeframe.
            </p>

            <p>
              We will need the following information provided below to verify your identity to make
              the request and confirm the personal information related to you.
            </p>

            <p>
              <strong>Please select your request and provide the following: </strong>
            </p>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <ManageMyDataForm query={query} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPageLayout(ManageMyDataPage, {
  isIndexed: true,
  pageTitle: 'manageMyData',
});
