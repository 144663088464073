import React, { lazy, Suspense } from 'react';

import FooterLink from 'components/FooterLink/FooterLink';

import {
  getAppUrlOrigin,
  getCurrentDomain,
  getFormattedDomainName,
  getViewParameter,
} from 'utilities/domain-detection/domain-detection';
import { domainNames } from 'utilities/domain-data/domain-data';
import { footerLinks } from 'utilities/labels/labels';
import {
  ABOUT_US,
  ADVERTISER_DISCLOSURE,
  CONTACT_US,
  FAQ,
  PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION,
  PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE,
  SECURITY,
} from 'static/links/sharedLinks';

const GFAFooterLinks = lazy(() => import('components/FooterLinks/GFAFooterLinks'));

const FooterLinks = () => {
  const appUrlOrigin = getAppUrlOrigin();

  const view = getViewParameter();

  const domainName = getFormattedDomainName();
  const domain = getCurrentDomain();

  if (domain === domainNames.GFA) {
    return (
      <Suspense fallback="Loading...">
        <GFAFooterLinks />
      </Suspense>
    );
  }

  return (
    <>
      <div className="col-6 col-md-3 mt-3 mt-md-0">
        <FooterLink header label={domainName} key={domainName} />

        <FooterLink label={footerLinks.about} url={`${appUrlOrigin}/${view}${ABOUT_US.href}`} />

        <FooterLink label={footerLinks.contact} url={`${appUrlOrigin}/${view}${CONTACT_US.href}`} />

        <FooterLink label={footerLinks.faq} url={`${appUrlOrigin}/${view}${FAQ.href}`} />
      </div>

      <div className="col-6 col-md-3 mt-3 mt-md-0">
        <FooterLink header label={footerLinks.support} />

        <FooterLink label={footerLinks.manageMyData} url={`/manage-my-data?view=${view}`} />

        <FooterLink
          label={footerLinks.doNotSell}
          url={`${PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION.href}?view=${view}`}
        />
      </div>

      <div className="col-6 col-md-3 mt-3 mt-md-0">
        <FooterLink header label={footerLinks.legal} />

        <FooterLink
          label={footerLinks.privacy}
          url={`${PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE.href}?view=${view}`}
        />

        <FooterLink
          label={footerLinks.advertiserDisclosure}
          url={`${appUrlOrigin}/${view}${ADVERTISER_DISCLOSURE.href}`}
        />

        <FooterLink label={footerLinks.security} url={`${appUrlOrigin}/${view}${SECURITY.href}`} />
      </div>

      <div className="col-6 col-md-3 mt-3 mt-md-0">
        <FooterLink header label={footerLinks.resources} />

        <FooterLink
          label={footerLinks.goBanking}
          external
          url="https://www.gobankingrates.com/category/credit/credit-score/"
        />

        <FooterLink
          label={footerLinks.goodScore}
          external
          url="https://www.gobankingrates.com/credit/credit-score/what-good-credit-score-anyway/"
        />

        <FooterLink
          label={footerLinks.increaseScore}
          external
          url="https://www.gobankingrates.com/credit/credit-score/raise-credit-score-100-points/"
        />

        <FooterLink
          label={footerLinks.repairScore}
          external
          url="https://www.gobankingrates.com/credit/credit-monitoring/best-credit-repair-companies/"
        />
      </div>
    </>
  );
};

export default FooterLinks;
