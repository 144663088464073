import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';

const AppStateContext = createContext();

/* eslint-disable react/jsx-filename-extension */
export const AppStateProvider = ({ initialState = {}, children }) => {
  return (
    <AppStateContext.Provider value={useState(initialState)}>{children}</AppStateContext.Provider>
  );
};
/* eslint-enable react/jsx-filename-extension */

AppStateProvider.propTypes = {
  initialState: PropTypes.shape({}),
  children: PropTypes.node,
};

export const useAppState = () => useContext(AppStateContext);
