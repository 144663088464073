import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import GBRLogo from 'components/GBRLogo/GBRLogo';
import FooterCopy from 'components/FooterCopy/FooterCopy';
import {
  ABOUT_US,
  ADVERTISER_DISCLOSURE,
  CONTACT_US,
  FAQ,
  SECURITY,
  PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION,
  PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE,
} from 'static/links/sharedLinks';
import { GBR_CREDIT_SCORES } from 'static/links/tuLinks';
import {
  getAppUrlOrigin,
  getFormattedDomainName,
  getViewParameter,
} from 'utilities/domain-detection/domain-detection';

const WrapperProps = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export const Wrapper = ({ children, className }) => {
  return <footer className={clsx('GBRFooter', className)}>{children}</footer>;
};

Wrapper.propTypes = WrapperProps;

const ContainerProps = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export const Container = ({ children, className }) => {
  return <div className={clsx('container-fluid', 'GBRFooterContainer', className)}>{children}</div>;
};

Container.propTypes = ContainerProps;

export const Content = () => {
  const appUrlOrigin = getAppUrlOrigin();

  const prettyDomain = getFormattedDomainName();

  const view = getViewParameter();

  const col1Links = [
    {
      ...ABOUT_US,
      href: `${appUrlOrigin}/${view}${ABOUT_US.href}`,
    },
    {
      ...CONTACT_US,
      href: `${appUrlOrigin}/${view}${CONTACT_US.href}`,
    },
    {
      ...FAQ,
      href: `${appUrlOrigin}/${view}${FAQ.href}`,
    },
  ];

  const col2Links = [
    {
      ...PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION,
      href: `${PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION.href}?view=${view}`,
    },
  ];

  const col3Links = [
    {
      ...PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE,
      href: `${PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE.href}?view=${view}`,
    },
    {
      ...ADVERTISER_DISCLOSURE,
      href: `${appUrlOrigin}/${view}${ADVERTISER_DISCLOSURE.href}`,
    },
    {
      ...SECURITY,
      href: `${appUrlOrigin}/${view}${SECURITY.href}`,
    },
  ];

  const col4Links = [GBR_CREDIT_SCORES];

  return (
    <>
      <GBRLogo className="domainLogo" />

      <div className="footerLinks">
        <div className="linkGroup">
          <div className="linkGroupHeader">{prettyDomain}</div>

          {col1Links.map(({ text, href, rel, target }) => {
            return (
              <a key={href} href={href} rel={rel} target={target}>
                {text}
              </a>
            );
          })}
        </div>

        <div className="linkGroup">
          <div className="linkGroupHeader">Support</div>

          {col2Links.map(({ text, href, rel, target }) => {
            return (
              <a key={href} href={href} rel={rel} target={target}>
                {text}
              </a>
            );
          })}
        </div>

        <div className="linkGroup">
          <div className="linkGroupHeader">Legal</div>

          {col3Links.map(({ text, href, rel, target }) => {
            return (
              <a key={href} href={href} rel={rel} target={target}>
                {text}
              </a>
            );
          })}
        </div>

        <div className="linkGroup">
          <div className="linkGroupHeader">Helpful Resources</div>

          {col4Links.map(({ text, href, rel, target }) => {
            return (
              <a key={href} href={href} rel={rel} target={target}>
                {text}
              </a>
            );
          })}
        </div>
      </div>

      <div aria-orientation="horizontal" className="separator" role="separator" />

      <div className="footerCopy">
        <FooterCopy
          contactUsLink={
            view === 'tu'
              ? {
                  ...CONTACT_US,
                  href: `${appUrlOrigin}/${view}${CONTACT_US.href}`,
                }
              : undefined
          }
          copyrightYear={new Date().getFullYear()}
          prettyDomain={prettyDomain}
          view={view}
        />
      </div>
    </>
  );
};

const PreBuiltLandingFooterV1 = () => (
  <Wrapper>
    <Container>
      <Content />
    </Container>
  </Wrapper>
);

export default PreBuiltLandingFooterV1;
