import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const ValidatedSelect = ({ value, onChange, options }) => {
  const handleChange = (selectedValue) => onChange('action', selectedValue);

  return (
    <Select
      onChange={handleChange}
      value={value}
      options={options}
      isSearchable={false}
      classNamePrefix="ValidatedSelect"
    />
  );
};

ValidatedSelect.propTypes = {
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
};

export default ValidatedSelect;
