import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import htmr from 'htmr';

import {
  getCurrentDomain,
  getFormattedDomainName,
  getViewParameter,
} from 'utilities/domain-detection/domain-detection';

const ECS_OFFER_DETAILS =
  '<p>DOMAIN_NAME_STYLED offers credit score and credit monitoring membership from Experian:</p><ol style=\\"list-style-type:decimal\\"><li><p>Experian CreditWorks Plus - $21.95 (plus any applicable tax) per month Credit Monitoring Subscription with $1 7-Day Trial<br />Your $1 Experian<sup>®</sup> Credit Report and FICO<sup>®</sup> Credit Score are available as part of a 7-day trial membership in Experian CreditWorks℠ Plus. You may cancel at any time during the trial period. At the end of your 7-day trial, you will be billed $21.95 per month (plus tax where applicable). <a href=\\"https://www.importantscore.com/ecs/r/d6ecaf48c83c8da6c881d0b6e85b66e8?subid=\\" target=\\"_blank\\" rel=\\"noreferrer\\">Learn more</a></p></li><li><p>Experian Free Credit Score With Experian Boost™<br />Your Experian<sup>®</sup> Credit Report and FICO<sup>®</sup> Credit Score are completely free. You may cancel your membership at any time.</p></li></ol>';

const TU_OFFER_DETAILS =
  '<p>DOMAIN_NAME_STYLED offers two types of TransUnion credit monitoring subscriptions:</p><ul style=\\"list-style-type:decimal\\"><li><p>$29.95 per month Credit Monitoring Subscription with $1 7-Day Trial <br />Your free TransUnion Credit Score & $1 Credit Report are available as part of a 7-day trial TransUnion subscription credit monitoring service. You may cancel at any time during the trial period. At the end of your 7-day trial, you will be billed $29.95 per month thereafter on a continuous basis until cancelled (plus tax where applicable).</p></li><li><p>$29.95 per month Credit Monitoring Subscription<br /> Your TransUnion Score & Report are available as part of the TransUnion subscription credit monitoring service. You will be billed at $29.95 per month thereafter on a continuous basis until cancelled (plus tax where applicable).</p></li></ul>';

const ContentBlockProps = {
  name: PropTypes.string,
  type: PropTypes.string,
  anchor: PropTypes.string,
  content: PropTypes.string,
};

const ContentBlock = ({ name = '', type = '', anchor = '', content = '' }) => {
  const [formattedContent, setFormattedContent] = useState('');

  useEffect(() => {
    const domain = getCurrentDomain();

    const domainNameStyled = getFormattedDomainName();

    const view = getViewParameter();

    let contentToStr = JSON.stringify(content);

    if (view === 'tu') {
      contentToStr = contentToStr.replace(/OFFER_DETAILS/g, TU_OFFER_DETAILS);
    } else {
      contentToStr = contentToStr.replace(/OFFER_DETAILS/g, ECS_OFFER_DETAILS);
    }

    contentToStr = contentToStr.replace(/DOMAIN_NAME_STYLED/g, domainNameStyled);
    contentToStr = contentToStr.replace(/DOMAIN_URL/g, `${domain}.com`);
    contentToStr = contentToStr.replace(/ORIGIN/g, window.location.origin);
    contentToStr = contentToStr.replace(/VIEW/g, view);

    contentToStr = JSON.parse(contentToStr);

    setFormattedContent(contentToStr);
  }, [content]);

  return (
    <div className="ContentBlock" id={anchor}>
      <h2 className={`ContentBlock__${type}`}>{name}</h2>
      {htmr(formattedContent)}
    </div>
  );
};

ContentBlock.propTypes = ContentBlockProps;

export default ContentBlock;
