import React from 'react';
import logo from 'static/images/cct-logo.png';

const CCTLogo = () => (
  <div className="CCTLogo">
    <img className="CCTLogo__image" src={logo} alt="Credit Checking Today Logo" />
  </div>
);

export default CCTLogo;
