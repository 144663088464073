import React from 'react';

import CCTLogo from 'components/CCTLogo/CCTLogo';
import GBRLogo from 'components/GBRLogo/GBRLogo';
import GFALogo from 'components/GFALogo/GFALogo';
import GoIPLogo from 'components/GoIPLogo/GoIPLogo';
import YSOLogo from 'components/YSOLogo/YSOLogo';

import { getCurrentDomain, getAppUrlOrigin } from 'utilities/domain-detection/domain-detection';
import { domainNames } from 'utilities/domain-data/domain-data';

const Logo = () => {
  const domain = getCurrentDomain();

  switch (domain) {
    case domainNames.CCT:
      return <CCTLogo />;
    case domainNames.GBR:
      return <GBRLogo />;
    case domainNames.GFA:
      return <GFALogo />;
    case domainNames.GOIP:
      return <GoIPLogo />;
    case domainNames.YSO:
      return <YSOLogo />;
    default:
      return null;
  }
};

const Header = () => {
  const appUrlOrigin = getAppUrlOrigin();

  return (
    <header className="Header">
      <div className="container-fluid Header__wrap">
        <a className="Header__link" href={appUrlOrigin} target="_blank" rel="noreferrer">
          <Logo />
        </a>
      </div>
    </header>
  );
};

export default Header;
