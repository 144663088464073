import React from 'react';
import PropTypes from 'prop-types';

const EcsFooterCopyProps = {
  copyrightYear: PropTypes.number.isRequired,
  prettyDomain: PropTypes.oneOf(['GOBankingRates.com', 'GOFreeCredit.com', 'ImportantScore.com'])
    .isRequired,
};

export const EcsFooterCopy = ({ copyrightYear, prettyDomain }) => {
  return (
    <>
      <p>
        This offer is only available to <abbr title="United States">U.S.</abbr> residents.
      </p>

      <p>
        &copy; {copyrightYear} All rights reserved. {prettyDomain}. This site is subject to our{' '}
        {prettyDomain} policies. By voluntarily providing us with information on this site, you
        consent to our use of that information in accordance with our privacy policy.
      </p>

      <p>
        Experian and the Experian marks used herein are trademarks or registered trademarks of
        Experian Information Solutions, Inc. Other product and company names mentioned herein are
        the property of their respective owners.
      </p>

      <p>
        The Site is not directed to individuals under the age of eighteen (18), and we request that
        such individuals not provide personal information through the Site. If you are under 18
        years of age, you may browse our Site; however, you may not provide personal information to
        us such as name, address, or email address, and you may not register for, enroll in, and/or
        make product purchases.
      </p>
    </>
  );
};

EcsFooterCopy.propTypes = EcsFooterCopyProps;

const TuFooterCopyProps = {
  contactUsLink: PropTypes.shape({
    href: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
  }).isRequired,
  copyrightYear: PropTypes.number.isRequired,
  prettyDomain: PropTypes.oneOf(['GOBankingRates.com', 'GOFreeCredit.com', 'ImportantScore.com'])
    .isRequired,
};

export const TuFooterCopy = ({ contactUsLink, copyrightYear, prettyDomain }) => {
  return (
    <>
      <p>
        This offer is only available to <abbr title="United States">U.S.</abbr> residents.
      </p>

      <p>
        &copy; {copyrightYear} {prettyDomain}. All rights reserved. This Site is subject to our{' '}
        {prettyDomain} policies. By voluntarily providing us with information on this Site, you
        consent to our use of that information in accordance with our privacy policy.
      </p>

      <p>
        {prettyDomain} is not a supplier of credit monitoring products or services and does not ever
        process payments or charge consumers directly for services. If you enroll in a service with
        TransUnion you may be charged by TransUnion in accordance to the terms and conditions
        presented to you at the time of enrollment. For information on how to access or manage your
        credit monitoring subscription see the{' '}
        <a href={contactUsLink.href} rel={contactUsLink.rel} target={contactUsLink.target}>
          contact us
        </a>{' '}
        page. TransUnion<sup>&reg;</sup> is a registered trademark by its owner.
      </p>

      <p>
        TransUnion Interactive, Inc. features TransUnion data for all educational credit scores.
        TransUnion Interactive, Inc. is a wholly owned subsidiary of TransUnion LLC.
      </p>

      <p>
        We define children as individuals under the age of 18. Our Website is not intended for the
        use of children and we do not knowingly solicit or collect information from children.
      </p>
    </>
  );
};

TuFooterCopy.propTypes = TuFooterCopyProps;

const FooterCopyProps = {
  contactUsLink: PropTypes.shape({
    href: PropTypes.string,
    target: PropTypes.string,
    rel: PropTypes.string,
  }),
  copyrightYear: PropTypes.number.isRequired,
  prettyDomain: PropTypes.oneOf(['GOBankingRates.com', 'GOFreeCredit.com', 'ImportantScore.com'])
    .isRequired,
  view: PropTypes.oneOf(['ecs', 'tu']).isRequired,
};

const FooterCopy = ({ view, ...props }) => {
  if (view === 'ecs') {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <EcsFooterCopy {...props} />;
  }

  if (view === 'tu') {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <TuFooterCopy {...props} />;
  }

  return null;
};

FooterCopy.propTypes = FooterCopyProps;

export default FooterCopy;
