import React from 'react';

import logo from 'static/images/gofreecredit-header-logo.svg';
import { getAppUrlOrigin } from 'utilities/domain-detection/domain-detection';

const GFCHeader = () => {
  const appUrlOrigin = getAppUrlOrigin();

  return (
    <header className="GFCHeader">
      <div className="container-fluid GFCHeader__wrap">
        <a className="GFCHeader__link" href={appUrlOrigin} target="_blank" rel="noreferrer">
          <img alt="Logo for www.gofreecredit.com" height="48" src={logo} width="144" />
        </a>
      </div>
    </header>
  );
};
export default GFCHeader;
