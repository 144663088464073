import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import routesHelper from 'utilities/routes-helper/routes-helper';

const IndexPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const route = routesHelper('privacyPolicy');

  let queryString = '';
  if (searchParams.size > 0) {
    queryString += `?${searchParams.toString()}`;
  }

  const url = `${route}${queryString}`;

  useEffect(() => {
    navigate(url, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return undefined;
};

export default IndexPage;
