import React from 'react';

import FooterLinks from 'components/FooterLinks/FooterLinks';
import FooterDisclaimer from 'components/FooterDisclaimer/FooterDisclaimer';

const Footer = () => {
  return (
    <footer className="Footer">
      <div className="container">
        <div className="row">
          <FooterLinks />
        </div>

        <div className="row">
          <FooterDisclaimer />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
