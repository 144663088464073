import React from 'react';

const GoIPLogo = () => (
  <div className="GoIPLogo">
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340.4 73.02">
      <rect fill="#333333" x="99.57" y="4.08" width="4.05" height="31.41" />
      <path
        fill="#333333"
        d="M110.7,4.5a58.52,58.52,0,0,1,8.62-.65c5.83,0,10,1.35,12.73,3.91S136.47,14,136.47,19s-1.58,9.32-4.52,12.22-7.78,4.51-13.89,4.51a66.29,66.29,0,0,1-7.36-.37Zm4.06,27.78a26.32,26.32,0,0,0,4.1.23c8.66,0,13.37-4.85,13.37-13.33.05-7.41-4.15-12.12-12.72-12.12a22.34,22.34,0,0,0-4.75.43Z"
      />
      <polygon
        fill="#333333"
        points="158 20.77 145.79 20.77 145.79 32.09 159.4 32.09 159.4 35.49 141.74 35.49 141.74 4.08 158.7 4.08 158.7 7.49 145.79 7.49 145.79 17.41 158 17.41 158 20.77"
      />
      <path
        fill="#333333"
        d="M164.67,35.49V4.08h4.42L179.16,20a88.21,88.21,0,0,1,5.64,10.2l.09-.05c-.37-4.19-.46-8-.46-12.91V4.08h3.82V35.49h-4.1l-10-15.93A100.67,100.67,0,0,1,168.3,9.07l-.14,0c.23,4,.33,7.73.33,13V35.49Z"
      />
      <polygon
        fill="#333333"
        points="201.29 7.53 191.74 7.53 191.74 4.08 214.99 4.08 214.99 7.53 205.39 7.53 205.39 35.49 201.29 35.49 201.29 7.53"
      />
      <rect fill="#333333" x="218.49" y="4.08" width="4.05" height="31.41" />
      <polygon
        fill="#333333"
        points="235.59 7.53 226.04 7.53 226.04 4.08 249.29 4.08 249.29 7.53 239.69 7.53 239.69 35.49 235.59 35.49 235.59 7.53"
      />
      <path
        fill="#333333"
        d="M261.08,35.49V22.17L251.16,4.08h4.61l4.42,8.67c1.22,2.38,2.15,4.29,3.13,6.48h.09c.89-2.05,2-4.1,3.17-6.48l4.52-8.67h4.61l-10.53,18V35.49Z"
      />
      <path d="M98.5,71.34V42.67h12.37q10.53,0,10.53,8.42T109.7,59.6h-2.88V71.34ZM106.82,57h1.87q4.53,0,4.53-6,0-5.62-4.53-5.63h-1.87Z" />
      <path d="M124.19,71.34V42.67h12.33a13.19,13.19,0,0,1,7.69,2,6.34,6.34,0,0,1,2.88,5.43q0,5.21-6.18,6.79c2.11.78,3.48,1.65,4.1,2.59s1.05,2.75,1.28,5.46q.39,4.82,1.61,6.38h-9a12.67,12.67,0,0,1-.86-5l0-1.7,0-2.14q0-5-4-5h-1.43V71.34Zm8.32-16.15h1.68q4.73,0,4.72-4.94t-4.72-4.94h-1.68Z" />
      <path d="M163.09,42.17q12.51,0,12.52,14.83T162.86,71.82q-12.75,0-12.75-15.16Q150.11,42.16,163.09,42.17Zm-.23,2.28a3.2,3.2,0,0,0-3.18,2.6c-.56,1.73-.83,5-.83,10s.27,8.21.83,9.94a3.25,3.25,0,0,0,6.36,0c.56-1.73.83-5,.83-9.94s-.27-8.2-.83-9.94S164.43,44.45,162.86,44.45Z" />
      <polygon points="199.41 42.67 199.41 45.55 192.37 45.55 192.37 71.34 183.55 71.34 183.55 45.55 176.47 45.55 176.47 42.67 199.41 42.67" />
      <polygon points="220.14 42.67 220.14 45.55 209.87 45.55 209.87 54.22 218.5 54.22 218.5 57.09 209.87 57.09 209.87 68.46 221.88 68.46 221.88 71.34 201.55 71.34 201.55 42.67 220.14 42.67" />
      <path d="M243.48,59.83l3.55.92c.05.73.08,1.36.08,1.89a8.23,8.23,0,0,1-3,6.68,12.32,12.32,0,0,1-8.11,2.5q-12.75,0-12.75-14.35,0-6.8,3.71-11a12.3,12.3,0,0,1,9.69-4.24q9.3,0,9.3,8.95l-3.56,1.65c0-.85.06-1.5.06-2q0-6.35-5.09-6.35Q232,44.49,232,58q0,11.58,6,11.57a5.17,5.17,0,0,0,4.13-2,7.46,7.46,0,0,0,1.65-4.94A21.17,21.17,0,0,0,243.48,59.83Z" />
      <polygon points="270.38 42.67 270.38 45.55 263.35 45.55 263.35 71.34 254.53 71.34 254.53 45.55 247.45 45.55 247.45 42.67 270.38 42.67" />
      <path d="M274,71.73a2.1,2.1,0,0,1-2.08-2.26,2.13,2.13,0,0,1,2.15-2.26,2.26,2.26,0,0,1,0,4.52Z" />
      <path d="M292.47,70.69a11.51,11.51,0,0,1-5,1c-5.24,0-8.64-3.58-8.64-8.88s3.66-9.22,9.31-9.22a10.55,10.55,0,0,1,4.38.9L291.82,57a7.29,7.29,0,0,0-3.66-.83c-4,0-6.12,2.94-6.12,6.56,0,4,2.58,6.49,6,6.49a9,9,0,0,0,3.87-.86Z" />
      <path d="M311.65,62.52c0,6.42-4.45,9.21-8.64,9.21-4.7,0-8.32-3.44-8.32-8.92,0-5.8,3.8-9.22,8.6-9.22C308.28,53.59,311.65,57.22,311.65,62.52Zm-13.77.18c0,3.8,2.19,6.67,5.27,6.67s5.27-2.83,5.27-6.74c0-2.94-1.47-6.66-5.2-6.66S297.88,59.41,297.88,62.7Z" />
      <path d="M315.63,58.69c0-1.8,0-3.27-.15-4.7h2.77l.14,2.8h.1a6,6,0,0,1,5.45-3.2,5.13,5.13,0,0,1,4.91,3.48h.08a7.23,7.23,0,0,1,1.93-2.26,6,6,0,0,1,3.84-1.22c2.29,0,5.7,1.51,5.7,7.53V71.34h-3.09V61.52c0-3.33-1.21-5.34-3.76-5.34a4,4,0,0,0-3.72,2.87,4.67,4.67,0,0,0-.26,1.58V71.34h-3.08V60.94c0-2.75-1.22-4.76-3.62-4.76a4.69,4.69,0,0,0-4.16,4.69V71.34h-3.08Z" />
      <path
        fill="#1a8240"
        stroke="#ccc"
        strokeWidth="2px"
        d="M36.19,72c-24-6.35-35-26.34-35.19-43.54C.85,13.4,9,3.91,12.64,1.3A45.06,45.06,0,0,0,23.27,2.51,58.6,58.6,0,0,0,36.19,1,58.79,58.79,0,0,0,49.12,2.51,45.12,45.12,0,0,0,59.75,1.3c3.64,2.61,11.79,12.09,11.64,27.15C71.21,45.65,60.25,65.64,36.19,72Z"
      />
      <path
        fill="#007a14"
        d="M36.19,72c-24-6.35-35-26.34-35.19-43.54C.85,13.4,9,3.91,12.64,1.3A45.06,45.06,0,0,0,23.27,2.51,58.6,58.6,0,0,0,36.19,1,58.79,58.79,0,0,0,49.12,2.51,45.12,45.12,0,0,0,59.75,1.3c3.64,2.61,11.79,12.09,11.64,27.15C71.21,45.65,60.25,65.64,36.19,72Z"
      />
      <path
        fill="#fff"
        d="M23.13,32.44H34.29V47.08H31.78l-.85-1.87A11.77,11.77,0,0,1,24,47.53q-6,0-9.35-3.6c-2.22-2.4-3.32-5.8-3.32-10.16s1.17-7.69,3.52-10.31a11.77,11.77,0,0,1,9.23-3.95q7.5,0,9.13,7.67l-3.69,1.69v-.66l-.06-1.47Q29.09,22,25,22A4,4,0,0,0,21,24.49c-.73,1.7-1.09,4.85-1.09,9.48q0,6.48.88,8.79a3.32,3.32,0,0,0,3.37,2.33C26.07,45.09,27,44,27,41.83V35.16h-3.9Z"
      />
      <path
        fill="#fff"
        d="M49.23,19.49q11.85,0,11.84,14t-12,14Q37,47.53,37,33.19,37,19.48,49.23,19.49ZM49,21.65a3,3,0,0,0-3,2.46c-.53,1.63-.78,4.77-.78,9.4s.25,7.77.78,9.41a3.07,3.07,0,0,0,6,0c.52-1.64.78-4.77.78-9.41s-.26-7.75-.78-9.39A3,3,0,0,0,49,21.65Z"
      />
    </svg>
  </div>
);

export default GoIPLogo;
