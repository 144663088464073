import React from 'react';

import withPageLayout from 'hocs/withPageLayout';

import ConfirmationImg from 'components/ConfirmationImg/ConfirmationImg';

import { FAQ } from 'static/links/sharedLinks';

import {
  getAppUrlOrigin,
  getCurrentDomain,
  getViewParameter,
} from 'utilities/domain-detection/domain-detection';
import { domainNames } from 'utilities/domain-data/domain-data';

const GenericConfirmationPage = () => {
  const appUrlOrigin = getAppUrlOrigin();

  const domain = getCurrentDomain();

  const view = getViewParameter();

  return (
    <div className="container-fluid Page">
      <div className="row align-items-center justify-content-center Page__image">
        <ConfirmationImg />
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-12 text-center">
          <h2 className="Page__header">We&apos;ll get things started for you</h2>

          <div className="Page__content" data-testid="generic-confirmation-copy">
            We have received your request, we will get things started on our end. We will do our
            best to get your request within forty-five (45) days of its receipt. If we require more
            time (up to 90 days), we will inform you of the reason and extension period in writing.
            If you have any questions or concerns, feel free to reach out{' '}
            <a href={`mailto:privacy@${domain}.com`}>privacy@{domain}.com</a>
            {domain === domainNames.GFA ? undefined : (
              <>
                {' '}
                or check out our{' '}
                <a href={`${appUrlOrigin}/${view}${FAQ.href}`} target="_blank" rel="noreferrer">
                  FAQ
                </a>
              </>
            )}
            .
          </div>

          <a
            href="https://www.gobankingrates.com/category/credit/credit-score/"
            data-testid="credit-score-resources"
          >
            <button className="Page__cta" type="button">
              Credit Score Resources
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default withPageLayout(GenericConfirmationPage, {
  pageTitle: 'genericConfirmation',
});
