import React from 'react';

import withPageLayout from 'hocs/withPageLayout';

import ConfirmationImg from 'components/ConfirmationImg/ConfirmationImg';

import { getFormattedName } from 'utilities/domain-detection/domain-detection';

const DoNotSellDataConfirmationPage = () => {
  const formattedName = getFormattedName();

  return (
    <div className="container-fluid Page">
      <div className="row align-items-center justify-content-center Page__image">
        <ConfirmationImg />
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-12 text-center">
          <h2 className="Page__header">Thank you for your submission</h2>

          <div className="Page__content">
            Thank you for submitting a request to the {formattedName} Data Management Portal. This
            is confirmation that your data will not be sold by or for {formattedName}. Thank you.
          </div>

          <a
            href="https://www.gobankingrates.com/category/credit/credit-score/"
            data-testid="credit-score-resources"
          >
            <button className="Page__cta" type="button">
              Credit Score Resources
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default withPageLayout(DoNotSellDataConfirmationPage, {
  pageTitle: 'doNotSellDataConfirmation',
});
