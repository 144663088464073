import React, { useEffect } from 'react';

import Nav from 'components/Nav/Nav';
import Content from 'components/Content/Content';

import withPageLayout from 'hocs/withPageLayout';

import {
  getAppUrlOrigin,
  getCurrentDomain,
  getViewParameter,
} from 'utilities/domain-detection/domain-detection';
import { domainNames } from 'utilities/domain-data/domain-data';

import * as privacyPolicyContent from 'static/content/privacy-policy/content.json';
import * as gbrPrivacyPolicyContent from 'static/content/privacy-policy/gobankingratesContent.json';
import * as gfcPrivacyPolicyContent from 'static/content/privacy-policy/gofreecreditContent.json';
import * as importantScorePrivacyPolicyContent from 'static/content/privacy-policy/importantscoreContent.json';

import * as privacyPolicyLinks from 'static/content/privacy-policy/links.json';
import * as gbrPrivacyPolicyLinks from 'static/content/privacy-policy/gobankingratesLinks.json';
import * as gfcPrivacyPolicyLinks from 'static/content/privacy-policy/gofreecreditLinks.json';
import * as importantScorePrivacyPolicyLinks from 'static/content/privacy-policy/importantscoreLinks.json';

const baseContent = privacyPolicyContent.default;
const gbrContent = gbrPrivacyPolicyContent.default;
const gfcContent = gfcPrivacyPolicyContent.default;
const isContent = importantScorePrivacyPolicyContent.default;

const baseLinks = privacyPolicyLinks.default;
const gbrLinks = gbrPrivacyPolicyLinks.default;
const gfcLinks = gfcPrivacyPolicyLinks.default;
const isLinks = importantScorePrivacyPolicyLinks.default;

const switchContent = (
  domain
  // view
) => {
  switch (domain) {
    case domainNames.CCT:
    case domainNames.GFA:
    case domainNames.YSO:
    case domainNames.GOIP:
      return [baseContent, baseLinks];
    case domainNames.GBR:
      return [gbrContent, gbrLinks];
    case domainNames.GFC:
      return [gfcContent, gfcLinks];
    case domainNames.IS:
      return [isContent, isLinks];
    default:
      return [isContent, isLinks];
  }
};

const PrivacyPolicyPage = () => {
  const appUrlOrigin = getAppUrlOrigin();

  const domain = getCurrentDomain();

  const view = getViewParameter();

  const [content, links] = switchContent(domain, view);

  // INFO: Redirect to GFA's privacy policy
  useEffect(() => {
    if (domain === domainNames.GFA) {
      window.location = `${appUrlOrigin}/privacy`;
    }
  }, [appUrlOrigin, domain]);

  // INFO: Render nothing for GFA
  if (domain === domainNames.GFA) {
    return null;
  }

  return (
    <div className="container-fluid Page">
      <div className="row">
        <div className="col-12 col-lg-3 p-lg-0 position-relative">
          <Nav data={links} />
        </div>

        <div className="col-12 col-lg-9">
          <Content data={content} />
        </div>
      </div>
    </div>
  );
};

export default withPageLayout(PrivacyPolicyPage, {
  isIndexed: true,
  pageTitle: 'privacyPolicy',
});
