import React from 'react';
import htmr from 'htmr';

import FooterCopy from 'components/FooterCopy/FooterCopy';
import logo from 'static/images/gofreecredit-bright-bg.svg';
import {
  GO_BANKING_RATES,
  GO_BANKING_RATES_HOW_TO_INCREASE_CREDIT_SCORE,
  GO_BANKING_RATES_WHAT_IS_A_GOOD_CREDIT_SCORE,
  GO_BANKING_RATES_WHAT_IS_THE_HIGHEST_CREDIT_SCORE,
  MEMBER_LOGIN,
} from 'static/links/ecsLinks';
import {
  ABOUT_US,
  ADVERTISER_DISCLOSURE,
  CONTACT_US,
  FAQ,
  SECURITY,
  PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION,
  PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE,
} from 'static/links/sharedLinks';
import {
  getAppUrlOrigin,
  getFormattedDomainName,
  getViewParameter,
} from 'utilities/domain-detection/domain-detection';

const GFCFooter = () => {
  const appUrlOrigin = getAppUrlOrigin();

  const prettyDomain = getFormattedDomainName();

  const view = getViewParameter();

  const col1Links = [
    {
      ...ABOUT_US,
      href: `${appUrlOrigin}/${view}${ABOUT_US.href}`,
    },

    {
      ...FAQ,
      href: `${appUrlOrigin}/${view}${FAQ.href}`,
    },

    MEMBER_LOGIN,
  ];

  const col2Links = [
    {
      ...CONTACT_US,
      href: `${appUrlOrigin}/${view}${CONTACT_US.href}`,
    },
  ];

  const col3Links = [
    {
      ...PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE,
      href: `${PIDA_PRIVACY_POLICY_AND_TERMS_OF_USE.href}?view=${getViewParameter()}`,
    },
    {
      ...ADVERTISER_DISCLOSURE,
      href: `${appUrlOrigin}/${view}${ADVERTISER_DISCLOSURE.href}`,
    },
    {
      ...SECURITY,
      href: `${appUrlOrigin}/${view}${SECURITY.href}`,
    },
    {
      ...PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION,
      href: `${PIDA_DO_NOT_SELL_MY_PERSONAL_INFORMATION.href}?view=${getViewParameter()}`,
    },
  ];

  const gbrLinks = [
    GO_BANKING_RATES,
    GO_BANKING_RATES_WHAT_IS_A_GOOD_CREDIT_SCORE,
    GO_BANKING_RATES_HOW_TO_INCREASE_CREDIT_SCORE,
    GO_BANKING_RATES_WHAT_IS_THE_HIGHEST_CREDIT_SCORE,
  ];

  return (
    <footer className="GFCFooter">
      <div className="container">
        <div className="content">
          <div className="row">
            <div className="col">
              <img src={logo} alt="Logo for www.gofreecredit.com" className="footer-logo" />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="row">
                <div className="col-6 col-md-3 col-lg-6 ">
                  <ul className="footer-link-group">
                    {col1Links.map(({ href, text, ...rest }) => (
                      <li key={href}>
                        <a
                          className="footer-link-group-anchor"
                          href={href}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...rest}
                        >
                          {htmr(text)}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="col-6 col-md-3 col-lg-6">
                  <ul className="footer-link-group">
                    {col2Links.map(({ href, text, ...rest }) => (
                      <li key={href}>
                        <a
                          className="footer-link-group-anchor"
                          href={href}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...rest}
                        >
                          {text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>

                <div className="col-12 col-md-6 col-lg-12">
                  <span className="footer-link-group-heading">Helpful Resources</span>

                  <ul className="footer-link-group">
                    {gbrLinks.map(({ href, text, ...rest }) => (
                      <li key={href}>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <a className="footer-link-group-anchor" href={href} {...rest}>
                          {text}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-8">
              <ul className="footer-other-links">
                {col3Links.map(({ href, text, ...rest }) => (
                  <li key={href}>
                    <a
                      className="footer-other-links-anchor"
                      href={href}
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...rest}
                    >
                      {text}
                    </a>
                  </li>
                ))}
              </ul>

              <hr className="footer-separator" />

              <div className="footer-copy">
                <FooterCopy
                  contactUsLink={
                    view === 'tu'
                      ? {
                          ...CONTACT_US,
                          href: `${appUrlOrigin}/${view}${CONTACT_US.href}`,
                        }
                      : undefined
                  }
                  copyrightYear={new Date().getFullYear()}
                  prettyDomain={prettyDomain}
                  view={view}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default GFCFooter;
