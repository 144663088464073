import React from 'react';
import PropTypes from 'prop-types';

import { Modal, ModalBody } from 'reactstrap';

const LoadingModal = ({ show, message = 'Loading' }) => {
  return (
    <Modal isOpen={show} className="LoadingModal">
      <ModalBody className="text-center pt-4 pb-4">
        <div className="d-inline-block align-middle lead">{message}</div>

        <div role="status" className="ml-2 align-middle LoadingModal__spinner">
          <span className="sr-only">Loading...</span>
        </div>
      </ModalBody>
    </Modal>
  );
};

LoadingModal.propTypes = {
  show: PropTypes.bool.isRequired,
  message: PropTypes.string,
};

export default LoadingModal;
