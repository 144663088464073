const ROOT = process.env.PUBLIC_URL;

// INFO: When adding/updating/removing a route remember to update `robots.txt`
// and update its <meta name="robots"> tag
const routes = {
  confirmRequest: '/c/:hash',
  deleteDataConfirmation: '/delete-confirmation',
  doNotSell: '/do-not-sell',
  doNotSellDataConfirmation: '/do-not-sell-confirmation',
  seeDataConfirmation: '/see-confirmation',
  error: '/error',
  genericConfirmation: '/thank-you',
  manageMyData: '/manage-my-data',
  privacyPolicy: '/privacy',
  verifyIdentification: '/verify',
};

export default (route) => {
  const mappedRoute = routes[route];

  if (mappedRoute) {
    return `${ROOT}${mappedRoute}`;
  }

  return `${ROOT}${route}`;
};
