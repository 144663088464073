export const domainNames = {
  CCT: 'creditcheckingtoday',
  GBR: 'gobankingrates',
  GFA: 'gofinancialadvice',
  GFC: 'gofreecredit',
  GOIP: 'goidentityprotect',
  IS: 'importantscore',
  YSO: 'yourscoreonline',
};

export const metaData = {
  gobankingrates: {
    titles: {
      confirmRequest: 'Verifying Request | GOBankingRates.com',
      deleteDataConfirmation: 'Delete Data Confirmation | GOBankingRates.com',
      doNotSellDataConfirmation: 'Do Not Sell Confirmation | GOBankingRates.com',
      seeDataConfirmation: 'See Data Confirmation | GOBankingRates.com',
      error: 'Something Went Wrong | GOBankingRates.com',
      genericConfirmation: 'Thank You | GOBankingRates.com',
      manageMyData: 'Manage My Data | GOBankingRates.com',
      notFound: '404 Page Not Found | GOBankingRates.com',
      privacyPolicy: 'Privacy Policy & Terms of Use | GOBankingRates.com',
      verifyIdentification: 'Verify ID | GOBankingRates.com',
    },
    description: 'GOBankingRates.com Privacy Policy and Terms of Use',
    favicon: 'faviconGBR.ico',
    gtmId: 'GTM-MWR5LD9',
  },

  gofinancialadvice: {
    titles: {
      confirmRequest: 'Verifying Request | GOFinancialAdvice.com',
      deleteDataConfirmation: 'Delete Data Confirmation | GOFinancialAdvice.com',
      doNotSellDataConfirmation: 'Do Not Sell Confirmation | GOFinancialAdvice.com',
      seeDataConfirmation: 'See Data Confirmation | GOFinancialAdvice.com',
      error: 'Something Went Wrong | GOFinancialAdvice.com',
      genericConfirmation: 'Thank You | GOFinancialAdvice.com',
      manageMyData: 'Manage My Data | GOFinancialAdvice.com',
      notFound: '404 Page Not Found | GOFinancialAdvice.com',
      privacyPolicy: 'Privacy Policy & Terms of Use | GOFinancialAdvice.com',
      verifyIdentification: 'Verify ID | GOFinancialAdvice.com',
    },
    description: 'GOFinancialAdvice.com Privacy Policy and Terms of Use',
    favicon: 'faviconGFA.ico',
    gtmId: 'GTM-WQ535CG',
  },

  gofreecredit: {
    titles: {
      confirmRequest: 'Verifying Request | GOFreeCredit.com',
      deleteDataConfirmation: 'Delete Data Confirmation | GOFreeCredit.com',
      doNotSellDataConfirmation: 'Do Not Sell Confirmation | GOFreeCredit.com',
      seeDataConfirmation: 'See Data Confirmation | GOFreeCredit.com',
      error: 'Something Went Wrong | GOFreeCredit.com',
      genericConfirmation: 'Thank You | GOFreeCredit.com',
      manageMyData: 'Manage My Data | GOFreeCredit.com',
      notFound: '404 Page Not Found | GOFreeCredit.com',
      privacyPolicy: 'Privacy Policy & Terms of Use | GOFreeCredit.com',
      verifyIdentification: 'Verify ID | GOFreeCredit.com',
    },
    description: 'GOFreeCredit.com Privacy Policy and Terms of Use',
    favicon: 'faviconGFC.ico',
    gtmId: 'GTM-T3K96TF',
  },

  yourscoreonline: {
    titles: {
      confirmRequest: 'Verifying Request | YourScoreOnline.com',
      deleteDataConfirmation: 'Delete Data Confirmation | YourScoreOnline.com',
      doNotSellDataConfirmation: 'Do Not Sell Confirmation | YourScoreOnline.com',
      seeDataConfirmation: 'See Data Confirmation | YourScoreOnline.com',
      error: 'Something Went Wrong | YourScoreOnline.com',
      genericConfirmation: 'Thank You | YourScoreOnline.com',
      manageMyData: 'Manage My Data | YourScoreOnline.com',
      notFound: '404 Page Not Found | YourScoreOnline.com',
      privacyPolicy: 'Privacy Policy & Terms of Use | YourScoreOnline.com',
      verifyIdentification: 'Verify ID | YourScoreOnline.com',
    },
    description: 'YourScoreOnline.com Privacy Policy and Terms of Use',
    favicon: 'faviconYSO.ico',
    gtmId: 'GTM-KMJJSKM',
  },

  creditcheckingtoday: {
    titles: {
      confirmRequest: 'Verifying Request | CreditCheckingToday.com',
      deleteDataConfirmation: 'Delete Data Confirmation | CreditCheckingToday.com',
      doNotSellDataConfirmation: 'Do Not Sell Confirmation | CreditCheckingToday.com',
      seeDataConfirmation: 'See Data Confirmation | CreditCheckingToday.com',
      error: 'Something Went Wrong | CreditCheckingToday.com',
      genericConfirmation: 'Thank You | CreditCheckingToday.com',
      manageMyData: 'Manage My Data | CreditCheckingToday.com',
      notFound: '404 Page Not Found | CreditCheckingToday.com',
      privacyPolicy: 'Privacy Policy & Terms of Use | CreditCheckingToday.com',
      verifyIdentification: 'Verify ID | CreditCheckingToday.com',
    },
    description: 'CreditCheckingToday.com Privacy Policy and Terms of Use',
    favicon: 'faviconCCT.ico',
    gtmId: 'GTM-MHHRT6S',
  },

  goidentityprotect: {
    titles: {
      confirmRequest: 'Verifying Request | GoIdentityProtect.com',
      deleteDataConfirmation: 'Delete Data Confirmation | GoIdentityProtect.com',
      doNotSellDataConfirmation: 'Do Not Sell Confirmation | GoIdentityProtect.com',
      seeDataConfirmation: 'See Data Confirmation | GoIdentityProtect.com',
      error: 'Something Went Wrong | GoIdentityProtect.com',
      genericConfirmation: 'Thank You | GoIdentityProtect.com',
      manageMyData: 'Manage My Data | GoIdentityProtect.com',
      notFound: '404 Page Not Found | GoIdentityProtect.com',
      privacyPolicy: 'Privacy Policy & Terms of Use | GoIdentityProtect.com',
      verifyIdentification: 'Verify ID | GoIdentityProtect.com',
    },
    description: 'GoIdentityProtect.com Privacy Policy and Terms of Use',
    favicon: 'faviconGOIP.ico',
    gtmId: 'GTM-TZFK5W8',
  },

  importantscore: {
    titles: {
      confirmRequest: 'Verifying Request | ImportantScore.com',
      deleteDataConfirmation: 'Delete Data Confirmation | ImportantScore.com',
      doNotSellDataConfirmation: 'Do Not Sell Confirmation | ImportantScore.com',
      seeDataConfirmation: 'See Data Confirmation | ImportantScore.com',
      error: 'Something Went Wrong | ImportantScore.com',
      genericConfirmation: 'Thank You | ImportantScore.com',
      manageMyData: 'Manage My Data | ImportantScore.com',
      notFound: '404 Page Not Found | ImportantScore.com',
      privacyPolicy: 'Privacy Policy & Terms of Use | ImportantScore.com',
      verifyIdentification: 'Verify ID | ImportantScore.com',
    },
    description: 'ImportantScore.com Privacy Policy and Terms of Use',
    favicon: 'faviconIS.ico',
    gtmId: 'GTM-M2LTQ4B',
  },
};
