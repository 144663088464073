export const formLabels = {
  fname: 'First Name',
  lname: 'Last Name',
  email: 'E-mail Address',
  phone: 'Phone Number',
  action: 'I want to:',
};

export const selectLabels = {
  seeMyData: 'See my personal data',
  doNotSell: 'Not sell my personal information',
  deleteMyData: 'Delete my personal data',
};

export const footerLinks = {
  about: 'About Us',
  faq: 'FAQ',
  contact: 'Contact Us',
  support: 'Support',
  manageMyData: 'Manage My Data',
  doNotSell: 'Do Not Sell My Personal Information',
  legal: 'Legal',
  privacy: 'Privacy Policy & Terms of Use',
  advertiserDisclosure: 'Advertiser Disclosure',
  security: 'Security',
  resources: 'Helpful Resources',
  goBanking: 'GOBankingRates',
  goodScore: 'What is a Good Credit Score?',
  increaseScore: 'How to Increase Credit Score?',
  repairScore: 'How to Repair Credit Score?',
};
