import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAppState } from 'contexts/app-state';

import withPageLayout from 'hocs/withPageLayout';

import confirmRequest from 'services/confirm-api';

import routes from 'utilities/routes-helper/routes-helper';

const CODE_TO_ROUTE = {
  e006: routes('deleteDataConfirmation'),
  e010: routes('seeDataConfirmation'),
};

const ConfirmRequestPage = () => {
  const navigate = useNavigate();
  const { hash } = useParams();

  const [appState, setAppState] = useAppState();

  const fetchRequestConfirmation = async () => {
    try {
      const {
        data: { code, data: userData },
      } = await confirmRequest(hash);

      const transitionTo = CODE_TO_ROUTE[code] ?? routes('genericConfirmation');
      const state = userData ? { userData } : undefined;

      navigate(transitionTo, { state });
    } catch {
      navigate(routes('error'), { replace: true });
    }
  };

  useEffect(() => {
    setAppState({
      ...appState,
      requestStatus: 'pending',
    });

    fetchRequestConfirmation();

    return () => {
      setAppState({
        ...appState,
        requestStatus: 'resolved',
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container-fluid Page">
      <div className="row align-items-center justify-content-center">
        <div className="col-12 text-center">
          <h2 className="mt-5 mb-5 Page__header">Please wait&hellip;</h2>

          <div className="text-center Page__content">
            <p>We&apos;re verifying your request: </p>
            <p className="font-weight-bold">{hash}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPageLayout(ConfirmRequestPage, {
  pageTitle: 'confirmRequest',
});
