/* eslint-disable import/prefer-default-export */

export const GBR_CREDIT_SCORES = {
  text: 'GOBankingRates',
  href: 'https://www.gobankingrates.com/category/credit/credit-score/',
  rel: 'noopener noreferrer',
  target: '_blank',
};

/* eslint-enable import/prefer-default-export */
