import React from 'react';

import logo from 'static/images/importantscore-header-logo.svg';
import experianLogo from 'static/images/experian-logo.svg';
import transUnionLogo from 'static/images/transunion-logo.svg';

import { getAppUrlOrigin } from 'utilities/domain-detection/domain-detection';

const ISHeader = () => {
  const appUrlOrigin = getAppUrlOrigin();

  return (
    <header className="ISHeader">
      <div className="container-fluid ISHeader__wrap">
        <a className="ISHeader__link" href={appUrlOrigin} target="_blank" rel="noreferrer">
          <img
            alt="Logo for www.importantscore.com"
            className="ISHeader__IS-logo"
            height="48"
            src={logo}
            width="194"
          />
        </a>

        <div className="ISHeader__logos">
          <img alt="TransUnion logo" height="44" src={transUnionLogo} width="129" />

          <img alt="Experian logo" height="44" src={experianLogo} width="124" />
        </div>
      </div>
    </header>
  );
};
export default ISHeader;
