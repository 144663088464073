import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { useAppState } from 'contexts/app-state';

import LoadingModal from 'components/LoadingModal/LoadingModal';

import DoNotSellPage from 'pages/DoNotSellPage/DoNotSellPage';
import IndexPage from 'pages/IndexPage/IndexPage';
import PrivacyPolicyPage from 'pages/PrivacyPolicyPage/PrivacyPolicyPage';
import ManageMyDataPage from 'pages/ManageMyDataPage/ManageMyDataPage';
import VerifyIdentificationPage from 'pages/VerifyIdentificationPage/VerifyIdentificationPage';
import GenericConfirmationPage from 'pages/GenericConfirmationPage/GenericConfirmationPage';
import DoNotSellDataConfirmationPage from 'pages/DoNotSellDataConfirmationPage/DoNotSellDataConfirmationPage';
import DeleteDataConfirmationPage from 'pages/DeleteDataConfirmationPage/DeleteDataConfirmationPage';
import SeeDataConfirmationPage from 'pages/SeeDataConfirmationPage/SeeDataConfirmationPage';
import ConfirmRequestPage from 'pages/ConfirmRequestPage/ConfirmRequestPage';
import ErrorPage from 'pages/ErrorPage/ErrorPage';
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage';

import routes from 'utilities/routes-helper/routes-helper';
import { getCurrentDomain } from 'utilities/domain-detection/domain-detection';

import { metaData } from 'utilities/domain-data/domain-data';

const Root = () => {
  const [{ requestStatus }] = useAppState();

  const isLoading = requestStatus === 'pending';

  const { gtmId, description, favicon } = metaData[getCurrentDomain()];

  useEffect(() => {
    TagManager.initialize({
      gtmId,
    });

    document.querySelector('meta[name="description"]').setAttribute('content', description);

    document
      .querySelector('link[rel="icon"]')
      .setAttribute('href', `${process.env.PUBLIC_URL}/favicon/${favicon}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoadingModal show={isLoading} />

      <Router basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route exact path="/" element={<IndexPage />} />
          <Route path={routes('privacyPolicy')} element={<PrivacyPolicyPage />} />
          <Route path={routes('doNotSell')} element={<DoNotSellPage />} />
          <Route path={routes('manageMyData')} element={<ManageMyDataPage />} />
          <Route path={routes('verifyIdentification')} element={<VerifyIdentificationPage />} />
          <Route path={routes('genericConfirmation')} element={<GenericConfirmationPage />} />
          <Route
            path={routes('doNotSellDataConfirmation')}
            element={<DoNotSellDataConfirmationPage />}
          />
          <Route path={routes('deleteDataConfirmation')} element={<DeleteDataConfirmationPage />} />
          <Route path={routes('seeDataConfirmation')} element={<SeeDataConfirmationPage />} />
          <Route path={routes('confirmRequest')} element={<ConfirmRequestPage />} />
          <Route path={routes('error')} element={<ErrorPage />} />
          <Route element={NotFoundPage} />
        </Routes>
      </Router>
    </>
  );
};

export default Root;
