import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import withPageLayout from 'hocs/withPageLayout';

import ConfirmationImg from 'components/ConfirmationImg/ConfirmationImg';

import routes from 'utilities/routes-helper/routes-helper';
import { getCurrentDomain, getViewParameter } from 'utilities/domain-detection/domain-detection';

const SeeDataConfirmationPage = () => {
  const { state } = useLocation();
  const userData = state?.userData ?? null;

  const hasUserData = Boolean(userData);

  const domain = getCurrentDomain();
  const view = getViewParameter();

  if (!hasUserData) {
    return <Navigate to={`${routes('manageMyData')}?view=${view}`} />;
  }

  return (
    <div className="container-fluid Page">
      <div className="row align-items-center justify-content-center Page__image">
        <ConfirmationImg />
      </div>

      <div className="row align-items-center justify-content-center">
        <div className="col-12 text-center">
          <h2 className="Page__header">See your data</h2>

          <div className="Page__content" data-testid="see-data-confirmation-copy">
            Thank you for submitting your request and verifying your identity. Your data is
            displayed below. If there are any questions or concerns, please reach out to{' '}
            <a href={`mailto:privacy@${domain}.com`}>privacy@{domain}.com</a>. Thank you.
          </div>

          <div className="Page__content">
            <table className="mt-5 table table-sm table-bordered user-data-table">
              <tbody>
                {Object.keys(userData).map((field) => {
                  return (
                    <tr key={field}>
                      <th scope="row">{field}</th>
                      <td>{userData[field]?.toString() ?? null}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withPageLayout(SeeDataConfirmationPage, {
  pageTitle: 'seeDataConfirmation',
});
